import { useRef, FC } from 'react'

import Slider from 'react-slick'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { SSliderHeader, SSliderTitle, SSliderWrapper, STitleWrapper } from './styles'
import Buttons from './Buttons'
import { ICardsSliderProps } from './types'


const CardsSlider: FC<ICardsSliderProps> = ({ children, linkToAll, title , settings, icon, sliderStyles, total, onTitleClick, className = '', hideAllBtn }) => {
  const sliderRef = useRef<Slider | null>(null)
  const router = useRouter()
  const goToPage = () => {
    if (!!linkToAll) router.push(linkToAll)
  }

  const handlePrev = () => sliderRef.current?.slickPrev()
  const handleNext = () => sliderRef.current?.slickNext()

  return (
    <div className={className}>
      <SSliderHeader>
        <STitleWrapper>
          <SSliderTitle onClick={onTitleClick || goToPage }>
            {!!icon && (
              <Image
                width={20}
                height={20}
                src={icon}
              />
            )}

            <span className={linkToAll ? 'active' : ''} >
              {title}
            </span>
          </SSliderTitle>
        </STitleWrapper>

        {settings?.slidesToShow < total && (
          <Buttons
            onClick={onTitleClick || goToPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            hideAllBtn
          />
        )}
      </SSliderHeader>

      <SSliderWrapper sliderStyles={sliderStyles}
        className='s-slider-wrapper'
      >
        <Slider
          {...settings}
          ref={(slider) => (sliderRef.current = slider)}
        >
          {children}
        </Slider>
      </SSliderWrapper>
    </div>
  )
}

export default CardsSlider
