import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'

export const SWinnersWrapper = styled.div`
  padding: 16px 24px;
`

export const SHeader = styled.div`
  ${FONTS.p1};
  color: ${COLORS.dark200};
  display: grid;
  grid-template-columns: 52px 1fr 70px;
  padding-bottom: 4px;
`

export const SHeaderCell = styled.div`
  ${FONTS.p2};
`

export const SRightAlign = styled.div`
  text-align: right;
`

export const SItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 52px 1fr 70px;
  padding: 12px 0;
  border-top: 2px solid ${COLORS.dark550};
`

export const SPrizeIcon = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
`

export const SPlayer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SAvatar = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
`

export const SPlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
`

export const SPlayerNickname = styled.div`
  ${FONTS.a1}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`

export const SSpentMoneyAmount = styled.div`
  ${FONTS.p2};
  color: ${COLORS.dark200};
`

export const SValue = styled.div`
  ${FONTS.a0};
  text-align: right;
  display: flex;
  justify-content: flex-end;
`

export const SPlaceholderPlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  flex: 1;
`

export const SPlaceholderImg = styled.div`
  width: 32px;
  height: 32px;
  background: ${COLORS.dark400};
  border-radius: 4px;
`

interface IPlaceholderTextProps {
  width?: string;
  height?: string;
}

export const SPlaceholderText = styled.div<IPlaceholderTextProps>`
  width: ${props => props.width ? props.width : '80%' };
  height: ${props => props.height ? props.height : '12px' };
  background: ${COLORS.dark400};
  border-radius: 4px;
`

