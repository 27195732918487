import styled, { css } from 'styled-components'
import { Button } from 'ui-kit'
import { COLORS, FONTS } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'

export const SSliderHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`

export const SSliderTitle = styled.div`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  color: ${COLORS.dark80};
  ${FONTS.h2};

  &:hover span.active {
    cursor: pointer;
    color: ${COLORS.white};
  }
`

export const STitleWrapper = styled.div`
  flex: 1;
`

export const SSliderButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const SButton = styled(Button)`
  min-width: 32px;
  box-sizing: border-box;
`

export const SButtonSlide = styled(SButton)`
  width: 32px;
  display: flex;
`

export const SSliderWrapper = styled.div<{ sliderStyles?: string }>`
  ${({ theme, sliderStyles }) => `
    ${sliderStyles ? sliderStyles : ''}

    ${applyStyles([SIZES.MOBILE], theme.contentSizeDefault, css`
      margin-left: -8px;
    `)}

    ${applyStyles([SIZES.W512, SIZES.W688, SIZES.W1040], theme.contentSizeDefault, css`
      margin-right: -16px;
    `)}
  `}
`

export const SArrowLeft = styled.div`
  transform: rotate(-180deg);
  display: flex;
  align-items: center;
`

export const STextButton = styled(SButton)`
  padding-left: 12px;
  padding-right: 12px;
`
