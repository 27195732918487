import { FC } from 'react'

import { pad } from 'utils/formatter'
import { pluralize } from 'numeralize-ru'
import { useTranslation } from 'next-i18next'

import { ICounter } from '../../types'

import {
  SCounter, SCounterItem, SCounterNumber, SCounterNumberLabel, SCounterSeparator, SCounterWrapper, SCounterLabel
} from './styles'
import useCountdownTimer from './useCountdownTimer'


const Counter: FC<ICounter> = ({ timeEnd }) => {
  const { days, hours, minutes } = useCountdownTimer(timeEnd, 20000)
  const { t } = useTranslation()

  return (
    <SCounterWrapper>
      <SCounterLabel>
        {t('tournaments_finish_in')}
      </SCounterLabel>

      <SCounter>
        <SCounterItem>
          <SCounterNumber>
            {pad('00', days)}
          </SCounterNumber>

          <SCounterNumberLabel>
            {pluralize(days, t('timer_days_1'), t('timer_days_2'), t('timer_days_5'))}
          </SCounterNumberLabel>
        </SCounterItem>

        <SCounterSeparator>
          :
        </SCounterSeparator>

        <SCounterItem>
          <SCounterNumber>
            {pad('00', hours)}
          </SCounterNumber>

          <SCounterNumberLabel>
            {pluralize(hours, t('timer_hours_1'), t('timer_hours_2'), t('timer_hours_5'))}
          </SCounterNumberLabel>
        </SCounterItem>

        <SCounterSeparator>
          :
        </SCounterSeparator>

        <SCounterItem>
          <SCounterNumber>
            {pad('00', minutes)}
          </SCounterNumber>

          <SCounterNumberLabel>
            {pluralize(minutes, t('timer_mins_1'), t('timer_mins_2'), t('timer_mins_5'))}
          </SCounterNumberLabel>
        </SCounterItem>
      </SCounter>
    </SCounterWrapper>
  )
}

export default Counter
