import { Settings } from 'react-slick'
import { SIZES } from 'context/layoutContext'

export const getSettings = (contentSize: SIZES): Settings => {
  let slidesToShow = 1
  if (contentSize === SIZES.W1040) slidesToShow = 3
  if (contentSize === SIZES.W688) slidesToShow = 2

  return {
    dots: false,
    arrows: false,
    infinite: false,
    initialSlide: 0,
    speed: 100,
    slidesToShow,
    slidesToScroll: slidesToShow,
    rows: 1,
    lazyLoad: 'progressive',
  }
}
