import { FC } from 'react'

import { useTranslation } from 'next-i18next'

import { IWinners } from '../../types'

import { SHeader, SHeaderCell, SRightAlign, SWinnersWrapper } from './styles'
import EmptyItem from './EmptyItem'
import Item from './Item'


const Winners: FC<IWinners> = ({ winners, currency }) => {
  const { t } = useTranslation()

  return (
    <SWinnersWrapper>
      <SHeader>
        <SHeaderCell>
          {t('tournaments_table_place')}
        </SHeaderCell>

        <SHeaderCell>
          {`${t('tournaments_table_player')} (${currency})`}
        </SHeaderCell>

        <SHeaderCell>
          <SRightAlign>
            {`${t('tournaments_table_prize')} (${currency})`}
          </SRightAlign>
        </SHeaderCell>
      </SHeader>

      <>
        {!!winners[0] ? <Item winner={winners[0]} /> : <EmptyItem />}

        {!!winners[1] ? <Item winner={winners[1]} /> : <EmptyItem />}

        {!!winners[2] ? <Item winner={winners[2]} /> : <EmptyItem />}
      </>
    </SWinnersWrapper>
  )
}

export default Winners
