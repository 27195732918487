import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'

export const SCounterWrapper = styled.div`
  background: rgba(255, 255, 255, 0.16);
  position: relative;
  padding: 24px 8px 8px;
  font-weight: 700;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SCounterLabel = styled.div`
  background: ${COLORS.yellow};
  padding: 4px 8px;
  position: absolute;
  left: 0;
  top: 0;
  ${FONTS.a3};
  color: ${COLORS.dark600};
  border-radius: 0 0 4px 0;
`

export const STitle = styled.div`
  ${FONTS.h2};
`

export const SCounter = styled.div`
  display: flex;
  gap: 8px;
`

export const SCounterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SCounterSeparator = styled.div`
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
`

export const SCounterNumber = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
`

export const SCounterNumberLabel = styled.div`
  ${FONTS.a3};
`


