import { FC } from 'react'

import Image from 'next/image'

import { IWinnersItem } from '../../types'

import { SAvatar, SItem, SPlayer, SPlayerInfo, SPlayerNickname, SPrizeIcon, SSpentMoneyAmount, SValue } from './styles'


const Item: FC<IWinnersItem> = ({ winner }) => (
  <SItem>
    <div>
      <SPrizeIcon>
        <Image
          width={32}
          height={32}
          src={`/img/Tournament/place${String(winner.userPosition)}.svg`}
        />
      </SPrizeIcon>
    </div>

    <SPlayer>
      <SAvatar>
        <Image
          width={32}
          height={32}
          src='/img/Tournament/profile.svg'
        />
      </SAvatar>

      <SPlayerInfo>
        <SPlayerNickname>
          {winner.nickname}
        </SPlayerNickname>

        <SSpentMoneyAmount>
          {winner.spentMoneyAmount}
        </SSpentMoneyAmount>
      </SPlayerInfo>
    </SPlayer>

    <SValue>
      {winner.bankWinAmount}
    </SValue>
  </SItem>
)

export default Item
