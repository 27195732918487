import { FC } from 'react'

import Image from 'next/image'
import { GradientButton } from 'ui-kit'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import { ITournamentCard } from '../types'

import Counter from './Counter'
import Winners from './Winners'
import {
  SContentWrapper, SFooter, SImg, SPrize, SPrizeLabel, SPrizeValue, STitle, STopBlock, STopContent, STournamentCard
} from './styles'


const TournamentCard: FC<ITournamentCard> = ({ tournament }) => {
  const { t } = useTranslation()
  const hasNotStats = tournament?.withoutParticipants

  return (
    <STournamentCard className={hasNotStats ? 'hasNotStats' : ''}>
      <STopBlock>
        {tournament.imageMobileSmall && (
          <SImg>
            <Image
              quality={100}
              src={tournament.imageMobileSmall}
              layout='fill'
            />
          </SImg>
        )}

        <SContentWrapper >
          <STopContent>
            <STitle>
              {tournament.tournamentName}
            </STitle>

            <Counter timeEnd={tournament.timeEnd} />

            <div>
              <SPrize>
                <SPrizeLabel>
                  {t('tournaments_prize_pool')}
                </SPrizeLabel>

                <SPrizeValue>
                  {`${tournament.totalBankMoneyAmount} ${tournament.currency}`}
                </SPrizeValue>
              </SPrize>
            </div>
          </STopContent>
        </SContentWrapper>
      </STopBlock>

      {!hasNotStats && (
        <Winners currency={tournament.currency}
          winners={tournament.standings}
        />
      )}

      {/*{<SFooter>
        <Link href={`/tournaments/${tournament.tournamentId}`}>
          <GradientButton>
            {t('tournaments_info')}
          </GradientButton>
        </Link>
      </SFooter>*/}
    </STournamentCard>
  )
}


export default TournamentCard
