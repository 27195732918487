import { FC } from 'react'

import { useTranslation } from 'next-i18next'

import { SArrowLeft, SButtonSlide, SSliderButtons, STextButton } from './styles'
import { ISliderButtonsProps } from './types'


const SliderButtons: FC<ISliderButtonsProps> = ({ handlePrev, handleNext, onClick, hideAllBtn }) => {
  const { t } = useTranslation()

  return (
    <SSliderButtons>
      {!hideAllBtn && onClick && (
        <STextButton
          onClick={onClick}
          color='dark'
          size='small'
        >
          {t('card_slider_all_button')}
        </STextButton>
      )}

      <SButtonSlide
        color='dark'
        size='small'
        onClick={handlePrev}
      >
        <SArrowLeft>
          <img
            width={20}
            height={20}
            src='/img/icons/arrow-right.svg'
          />
        </SArrowLeft>
      </SButtonSlide>

      <SButtonSlide
        color='dark'
        size='small'
        onClick={handleNext}
      >
        <img
          width={20}
          height={20}
          src='/img/icons/arrow-right.svg'
        />
      </SButtonSlide>
    </SSliderButtons>
  )
}

export default SliderButtons
