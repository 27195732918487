import  { FC } from 'react'

import {
  SAvatar, SItem, SPlaceholderImg, SPlaceholderPlayerInfo, SPlaceholderText, SPlayer, SPlayerNickname, SPrizeIcon,
  SSpentMoneyAmount, SValue
} from './styles'

const EmptyItem: FC = () => (
  <SItem>
    <div>
      <SPrizeIcon>
        <SPlaceholderImg />
      </SPrizeIcon>
    </div>

    <SPlayer>
      <SAvatar>
        <SPlaceholderImg />
      </SAvatar>

      <SPlaceholderPlayerInfo>
        <SPlayerNickname>
          <SPlaceholderText />
        </SPlayerNickname>

        <SSpentMoneyAmount>
          <SPlaceholderText height='10px' width='60%' />
        </SSpentMoneyAmount>
      </SPlaceholderPlayerInfo>
    </SPlayer>

    <SValue>
      <SPlaceholderText />
    </SValue>
  </SItem>
)


export default EmptyItem
