import { FC, useMemo } from 'react'

import { useTranslation } from 'next-i18next'
import { useLayoutContext } from 'context/layoutContext'
import CardsSlider from 'components/pages-blocks/Components/CardSlider'

import TournamentCard from './TournamentCard'
import { getSettings } from './helper'
import { ITournaments } from './types'


const Tournaments: FC<ITournaments> = ({ tournaments, withoutStatistic  }) => {
  const { t } = useTranslation()
  const { contentSize } = useLayoutContext()
  const settings = useMemo(() => getSettings(contentSize), [contentSize])

  return (
    <CardsSlider
      total={tournaments.length}
      icon='/img/home-page/sliders-icons/tournaments.svg'
      title={t('home_page_tournaments_title')}
      // linkToAll='/tournaments'
      settings={settings}
    >
      {tournaments?.map(tournament => (
        <TournamentCard
          key={tournament.tournamentId}
          tournament={tournament}
          withoutStatistic={withoutStatistic}
        />
      ))}
    </CardsSlider>
  )
}

export default Tournaments
