import styled, { css } from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'

export const STopBlock = styled.div`
  padding: 24px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`

export const SContentWrapper = styled.div`
  margin-left: 127px;
`

export const STitle = styled.div`
  ${FONTS.h2};
`

export const STournamentCard = styled.div`
  max-width: 336px;
  min-height: 196px;
  background: ${COLORS.dark600};
  overflow: hidden;
  border-radius: 8px;
  margin: 0;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      max-width: 100%;
      margin-left: 8px;
    `)}

    ${applyStyles([SIZES.W688, SIZES.W1040], theme.contentSize, css`
     max-width: 336px;
    `)}
  `};

  &.hasNotStats {
    min-height: 466px;
    display: flex;
    flex-direction: column;

    ${STopBlock} {
      flex: 1;
    }

    ${SContentWrapper} {
      margin-left: 0;
      margin: auto;
      margin-top: 217px;
      max-width: 240px;
    }

    ${STitle} {
      text-align: center;
    }
  }
`

export const SImg = styled.div`
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  border-radius: 8px;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      width: calc(100vw - 24px);
      height: calc((100vw - 24px) * 0.58);
    `)}

    ${applyStyles([SIZES.W688, SIZES.W1040], theme.contentSize, css`
      width: 100%;
      max-width: 336px;
      height: 197px;
    `)}
  `};
`

export const STopContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  gap: 8px;
  flex-direction: column;
`

export const SCounterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SCounterSeparator = styled.div`
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
`

export const SPrize = styled.div`
  border-radius: 4px;
  background: ${COLORS.yellow};
  padding: 4px 8px;
  display: inline-block;
  width: 100%;
`

export const SPrizeLabel = styled.div`
  ${FONTS.a3};
  color: ${COLORS.dark600};
  text-transform: uppercase;
`

export const SPrizeValue = styled.div`
  ${FONTS.h2};
  color: ${COLORS.dark600};
`

export const SFooter = styled.div`
  padding: 12px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid var(--dark-550, #272832);
`

